const numberFormatter = new Intl.NumberFormat("en-us", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatNumber = (value: any): string => {
  if (typeof value !== "number") return "";

  return numberFormatter.format(value);
};
