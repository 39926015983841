// src/public/watchlistRow.tsx

import { useQuoteStream } from "../hooks/use-feed";
import { TickValue } from "./tickValue";

export const WatchlistRow = (props: { stockTicker: string }) => {
  const { quotes } = useQuoteStream(props.stockTicker);

  const last = quotes.Trade?.price as number;
  const bid = quotes.Quote?.bidPrice as number;
  const ask = quotes.Quote?.askPrice as number;

  return (
    <tr className="text-right border-b border-gray-400 last:border-none">
      <td className="font-bold text-left text-white">{props.stockTicker}</td>
      <td>
        <TickValue value={last} />
      </td>
      <td>
        <TickValue value={bid} />
      </td>
      <td>
        <TickValue value={ask} />
      </td>
    </tr>
  );
};
