// src/components/account-info.tsx

import { useTastyAuthContext } from "../context/tasty-auth-context";

export const AccountInfo = () => {
  const { isLoggedIn, accounts } = useTastyAuthContext();

  if (!isLoggedIn) return <>Not logged in</>;

  return (
    <>
      {accounts
        .map(
          ({ account }) => `${account.nickname} (${account["account-number"]})`
        )
        .join(" ")}
    </>
  );
};
