import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { Account } from "../utils/types";

export const TastyAuthContext = createContext<ReturnType<typeof useTastyAuth>>(
  {} as unknown as ReturnType<typeof useTastyAuth>
);

export const useTastyAuthContext = () => {
  return useContext(TastyAuthContext);
};

const useTastyAuth = (): {
  accounts: { account: Account }[];
  setAccounts: (accounts: { account: Account }[]) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
} => {
  const [accounts, setAccounts] = useState<{ account: Account }[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  return {
    accounts,
    setAccounts: useCallback(
      (accounts: { account: Account }[]) => setAccounts(accounts),
      []
    ),
    isLoggedIn,
    setIsLoggedIn: useCallback(
      (isLoggedIn: boolean) => setIsLoggedIn(isLoggedIn),
      []
    ),
  };
};

export const TastyAuthContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <TastyAuthContext.Provider value={useTastyAuth()}>
      {children}
    </TastyAuthContext.Provider>
  );
};
