import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { AppContext, TastytradeContext } from "../context/context";
import { TastyAuthContextProvider } from "../context/tasty-auth-context";

export const Root = () => {
  const context = useMemo(
    () =>
      new TastytradeContext(
        "https://api.cert.tastyworks.com",
        "wss://streamer.cert.tastyworks.com"
      ),
    []
  );

  return (
    <AppContext.Provider value={context}>
      <TastyAuthContextProvider>
        <Outlet />
      </TastyAuthContextProvider>
    </AppContext.Provider>
  );
};
