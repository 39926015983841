import { Navigate, Outlet } from "react-router-dom";
import { Navbar } from "../components/navbar";
import { Watchlist } from "../components/watchlist";
import { AccountInfo } from "../components/account-info";
import React from "react";
import { useTastyAuthContext } from "../context/tasty-auth-context";

export const App = () => {
  const { isLoggedIn } = useTastyAuthContext();

  if (!isLoggedIn) return <Navigate to="/login" />;

  return (
    <>
      <div className="flex flex-row h-screen bg-black text-gray-400">
        <div className="basis-[19.5%] bg-gray-900">
          <Navbar />
          <Watchlist />
        </div>
        <div className="basis-[54%] bg-gray-800">
          <Outlet />
        </div>
        <div className="basis-[26.5%] bg-gray-900">
          <AccountInfo />
        </div>
      </div>
    </>
  );
};
