import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

const DEFAULT_FALLBACK_TICKER = "AAPL";

export const Tickers = () => {
  const params = useParams();

  if (!params.symbol)
    return <Navigate to={`/symbols/${DEFAULT_FALLBACK_TICKER}`} />;

  return (
    <>
      <Outlet />
    </>
  );
};
