// src/components/order-input.tsx

import React, { FormEvent, useContext, useRef, useState } from "react";
import { AppContext } from "../context/context";

export const OrderInput = ({ symbol }: { symbol: string }) => {
  const context = useContext(AppContext);
  const formRefDeux = useRef(null);
  const [orderInfo, setOrderInfo] = useState<{ [key: string]: any }>();
  const [error, setError] = useState("");

  enum TimeInForce {
    Day = "Day",
    GTC = "GTC",
    GTD = "GTD",
    Ext = "Ext",
    "GTC Ext" = "GTC Ext",
    IOC = "IOC",
  }

  enum OrderType {
    Limit = "Limit",
    Market = "Market",
    "Marketable Limit" = "Marketable Limit",
    Stop = "Stop",
    "Stop Limit" = "Stop Limit",
    "Notional Market" = "Notional Market",
  }

  enum Action {
    "Sell to Open" = "Sell to Open",
    "Sell to Close" = "Sell to Close",
    "Buy to Open" = "Buy to Open",
    "Buy to Close" = "Buy to Close",
    Sell = "Sell",
    Buy = "Buy",
  }

  interface EquityOrder {
    "time-in-force": TimeInForce;
    "gtc-date": string;
    price: number;
    "price-effect": string;
    "order-type": OrderType;
    legs: EquityOrderLeg[];
  }
  // TODO: make BTO/STO / BTC/STC dependend on if the account has already a position

  interface EquityOrderLeg {
    "instrument-type": string;
    symbol: string;
    quantity: number;
    action: Action;
  }
  const customOrder = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const fd = new FormData(formRefDeux.current!);
    const order: EquityOrder = {
      "time-in-force": fd.get("tif") as TimeInForce,
      "gtc-date": fd.get("gtc-date") as string,
      price: parseFloat(fd.get("price")! as string),
      "price-effect": "Credit",
      "order-type": fd.get("ordertype") as OrderType,
      legs: [
        {
          "instrument-type": "Equity",
          symbol,
          quantity: parseFloat(fd.get("orderquantity") as string),
          action: fd.get("orderaction") as Action,
        },
      ],
    };

    if (
      order.legs[0]["action"] === "Buy to Open" ||
      order.legs[0]["action"] === "Buy to Close"
    ) {
      order["price-effect"] = "Debit";
    }

    try {
      const sentOrder =
        await context.tastytradeApi.orderService.postOrderDryRun(
          context.accountNumbers[0],
          order
        );
      console.log("Order:", sentOrder);
      setOrderInfo(sentOrder);
    } catch (err: any) {
      setError(err.message);
    }

    console.log("Custom Order:", { order });
  };

  return (
    <div>
      <div className="text-xl font-semibold pb-3">
        {error && <h3>error: {error}</h3>}
        <h2>Place your {symbol} order:</h2>
      </div>
      <form ref={formRefDeux} onSubmit={(event) => customOrder(event)}>
        <div className="grid grid-cols-6 gap-3">
          <div className="">
            <label>Order Action</label>
            <br />
            <select
              className="p-1 rounded-lg focus:bg-amber-50"
              name="orderaction"
              id="orderaction"
            >
              <option value="Sell to Open">Sell</option>
              <option value="Buy to Open">Buy</option>
            </select>
            <br />
            <br />
          </div>
          <div className="">
            <label>Quantity</label>
            <br />
            <input
              className="p-1 rounded-lg w-16 focus:bg-amber-50"
              type="text"
              id="orderquantity"
              name="orderquantity"
              defaultValue="100"
            />
            <label htmlFor="orderquantity" />
            <br />
            <br />
          </div>
          <div className="">
            <label>Price</label>
            <br />
            <input
              className="p-1 rounded-lg w-16 focus:bg-amber-50"
              type="text"
              id="price"
              name="price"
              defaultValue="5"
            />
            <label htmlFor="price" />
            <br />
            <br />
          </div>
          <div className="">
            <label>Order Type</label>
            <br />
            <select
              className="p-1 rounded-lg focus:bg-amber-50"
              name="ordertype"
              id="ordertype"
            >
              <option value="Limit">Limit</option>
              <option value="Market">Market</option>
              <option value="Stop">Stop Market</option>
              <option value="Stop Limit">Stop Limit</option>
            </select>
            <br />
            <br />
          </div>
          <div className="">
            <label>Time in force</label>
            <br />
            <select
              className="p-1 rounded-lg focus:bg-orange-50"
              name="tif"
              id="tif"
            >
              <option value="Day">Day</option>
              <option value="GTC">GTC</option>
              <option value="GTD">GTD</option>
              <option value="Ext">Ext</option>
              <option value="GTC Ext">GTC Ext</option>
              <option value="IOC">IOC</option>
            </select>
          </div>
          <div className="">
            <label>GTD (YYYY-MM-DD)</label>
            <br />
            <input
              className="p-1 rounded-lg w-28 focus:bg-amber-50"
              type="date"
              id="gtc-date"
              name="gtc-date"
            />
          </div>
          <div className="col-span-5">
            {orderInfo && (
              <>
                {orderInfo.order["order-type"]}{" "}
                {orderInfo.order["legs"][0]["action"]}{" "}
                {orderInfo.order["legs"][0]["quantity"]}
                {"x "}
                {orderInfo.order["underlying-symbol"]} @{" "}
                {orderInfo.order["price"]} {orderInfo.order["time-in-force"]}{" "}
                {orderInfo.order["gtc-date"]}
                <br />
                {orderInfo.warnings[0] && (
                  <>Info: {orderInfo.warnings[0]["message"]}</>
                )}
              </>
            )}
          </div>
          <div className="">
            <input
              className="p-2 rounded-lg w-32 hover:bg-amber-100 bg-orange-400"
              type="submit"
              value="Send >"
            />
          </div>
        </div>
        <br />
        <br />
      </form>
    </div>
  );
};
