import { OrderInput } from "../components/order-input";
import React from "react";
import { useParams } from "react-router-dom";
import { TopInfoPanel } from "../components/top-info-panel";

export const Ticker = () => {
  const params = useParams();

  return (
    <>
      <TopInfoPanel symbol={params.symbol as string} />
      <div className="bg-gray-900">
        <div className="bg-gray-800">
          <OrderInput symbol={params.symbol as string} />
        </div>
      </div>
    </>
  );
};
