import { LoginMask } from "../components/login-mask";
import { useTastyAuthContext } from "../context/tasty-auth-context";
import { Navigate } from "react-router-dom";
import React from "react";

export const Login = () => {
  const { isLoggedIn } = useTastyAuthContext();

  if (isLoggedIn) return <Navigate to="/" />;

  return (
    <>
      <LoginMask />
    </>
  );
};
