// src/components/tickValue.tsx

import { useEffect, useState } from "react";
import { formatNumber } from "../utils/formatter";

export const TickValue = (props: { value: number }) => {
  const [prevValue, setPrevValue] = useState(props.value);
  const [colorClass, setColorClass] = useState("text-gray-600");

  useEffect(() => {
    if (props.value !== prevValue) {
      setColorClass(
        props.value! < prevValue! ? "text-red-600" : "text-lime-600"
      );
      setPrevValue(props.value);
    }
  }, [props.value, prevValue]);

  return <div className={colorClass}>{formatNumber(props.value)}</div>;
};
