// src/components/login-mask.tsx

import React, { FormEvent, useContext, useRef, useState } from "react";
import { AppContext, TastytradeContext } from "../context/context";
import { useTastyAuthContext } from "../context/tasty-auth-context";
import { Account } from "../utils/types";
import { useNavigate } from "react-router-dom";

async function fetchAccounts(context: TastytradeContext) {
  const accounts: {
    account: Account;
  }[] =
    await context.tastytradeApi.accountsAndCustomersService.getCustomerAccounts();
  const extractedAccountNumbers = accounts.map(
    (item: any) => item.account["account-number"]
  );

  if (extractedAccountNumbers.length) {
    context.accountNumbers = extractedAccountNumbers;
  }

  return accounts;
}

export function LoginMask() {
  const formRef = useRef(null);
  const context = useContext(AppContext);
  const { setIsLoggedIn, setAccounts } = useTastyAuthContext();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const tryLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const fd = new FormData(formRef.current!);
    console.log("test submit", {
      username: fd.get("username"),
      password: fd.get("password"),
    });

    try {
      await context.tastytradeApi.sessionService.login(
        fd.get("username") as string,
        fd.get("password") as string
      );
      const accounts = await fetchAccounts(context);

      const accbalances =
        await context.tastytradeApi.balancesAndPositionsService.getAccountBalanceValues(
          context.accountNumbers![0]
        );

      // setup quote streamer
      const response =
        await context.tastytradeApi.accountsAndCustomersService.getQuoteStreamerTokens();

      const url = `${response["websocket-url"]}/cometd`;
      const quoteStreamer = context.setupQuoteStreamer(response.token, url);
      quoteStreamer.connect();

      setAccounts(accounts);
      setIsLoggedIn(true);
      console.log("logged into tasty", accounts);
      console.log("Cash:", accbalances["cash-balance"], "USD");
      console.log("Buying-Power:", accbalances["equity-buying-power"], "USD");
      navigate("/symbols");
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <div className="login">
      <header className="loginmask">
        {error && <h2>error: {error}</h2>}
        <form ref={formRef} onSubmit={(event) => tryLogin(event)}>
          <div className="flex flex-col">
            <label htmlFor="username">Username:</label>
            <input type="text" id="username" name="username"></input>
            <label htmlFor="password">Password:</label>
            <input type="password" id="password" name="password"></input>
            <button type="submit">login</button>
          </div>
        </form>
      </header>
    </div>
  );
}
