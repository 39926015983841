// src/components/top-info-panel.tsx

import React, { FormEvent, useContext, useRef, useState } from "react";
import { AppContext } from "../context/context";
import { useQuoteStream } from "../hooks/use-feed";
import { useTastyAuthContext } from "../context/tasty-auth-context";
import { TickValue } from "./tickValue";
import { useNavigate } from "react-router-dom";

interface SymbolData {
  stockTicker: string;
  description: string;
  "listed-market": string;
}

export const TopInfoPanel = (props: { symbol: string }) => {
  const context = useContext(AppContext);
  const formRef = useRef(null);
  const [error, setError] = useState("");
  const [symbolInfo, setSymbolInfo] = useState<SymbolData>();
  const [symbol, setSymbol] = useState<string | undefined>(props.symbol);
  const { quotes } = useQuoteStream(symbol);
  const { isLoggedIn } = useTastyAuthContext();
  const navigate = useNavigate();

  if (!isLoggedIn)
    return <div className="p-4">Log in to view the symbol input</div>;

  const handleFocus = (event: any) => event.target.select();

  const searchStockTicker = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const fd = new FormData(formRef.current!);
    const stockTicker = (fd.get("stockTicker") as string).toUpperCase();

    try {
      const tickerDetail =
        await context.tastytradeApi.instrumentsService.getSingleEquity(
          stockTicker
        );
      (document.activeElement as HTMLElement | undefined)?.blur(); // remove focus (blur)
      setSymbol(tickerDetail["streamer-symbol"]);
      setSymbolInfo(tickerDetail);
      navigate(`/symbols/${tickerDetail["streamer-symbol"]}`);
      console.log("Instrument:", tickerDetail);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const last = (quotes.Trade?.price as number) ?? 0;
  const lastSize = (quotes.Trade?.size as number) ?? 0;
  const change = (quotes.Trade?.change as number) ?? 0;
  const bid = (quotes.Quote?.bidPrice as number) ?? 0;
  const ask = (quotes.Quote?.askPrice as number) ?? 0;
  const size = `${quotes.Quote?.askSize}x${quotes.Quote?.bidSize}` ?? "0";
  const volume = (quotes.Trade?.dayVolume as number) ?? 100;
  const description = (quotes.Profile?.description as string) ?? "NaN";

  return (
    <div className="text-lg">
      <div className="grid grid-cols-11 gap-x-1 text-right bg-black text-gray-400 pt-2 pb-4">
        <form
          className=" row-span-2 bg-red-500"
          ref={formRef}
          onSubmit={(event) => searchStockTicker(event)}
        >
          <input
            className="h-full w-full uppercase text-3xl bg-black font-light text-white
            hover:bg-gray-800 hover:text-lg hover:border hover:border-gray-400
            focus:bg-gray-800 focus:text-lg focus:border-dashed focus:border-gray-400"
            type="text"
            id="stockTicker"
            name="stockTicker"
            defaultValue={symbol}
            onFocus={handleFocus}
          ></input>
        </form>
        <div className=" text-xs">
          <span className="align-bottom">IV Rank</span>
        </div>
        <div className=" text-xs">Last</div>
        <div className=" text-xs text-left">Size</div>
        <div className=" text-xs">Chg</div>
        <div className=" text-xs">Bid</div>
        <div className=" text-xs">Ask</div>
        <div className=" text-xs">Size</div>
        <div className=" text-xs text-left">Volume</div>
        <div className=" text-xs col-span-2 text-left">
          {symbolInfo && <h3>{symbolInfo["listed-market"]}</h3>}
        </div>
        {
          //////////////////////////////////////////////////////////////////
        }
        <div className="">15</div>
        <div className=" text-right">
          <TickValue value={last} />
        </div>
        <div className=" text-left">
          {lastSize.toLocaleString("en-us", {
            notation: "compact",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </div>
        <div className="">
          <TickValue value={change} />
        </div>
        <div className="">
          <TickValue value={bid} />
        </div>
        <div className="">
          <TickValue value={ask} />
        </div>
        <div className="">{size}</div>
        <div className=" text-left">
          {volume.toLocaleString("en-us", {
            notation: "compact",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </div>
        <div className="text-sm col-span-2 truncate text-left">
          {description}
        </div>
      </div>
      {error && <h3>error: {error}</h3>}
    </div>
  );
};
