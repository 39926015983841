import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/context";
import { EventType, IEvent } from "@dxfeed/api/lib/interfaces";

export const useQuoteStream = (symbol: string | undefined) => {
  const context = useContext(AppContext);
  const [quotes, setQuotes] = useState<Partial<Record<EventType, IEvent>>>({});

  useEffect(() => {
    if (!symbol) return;

    console.log("subscribing", symbol);
    const unsubscribe = context.quoteStreamer?.subscribe(symbol, (event) => {
      setQuotes((quotes) => ({
        ...quotes,
        [event.eventType]: event,
      }));
    });

    return () => {
      console.log("unsubscribing", symbol);
      unsubscribe?.();
    };
  }, [symbol]);

  //console.log(symbol, quotes);

  return { quotes };
};
