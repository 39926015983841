// srv/components/navbar.tsx

export const Navbar = () => {
  return (
    <>
      <header className="text-white">
        <div className="container mx-auto pt-1 px-2 pb-2 flex justify-between">
          <a href="/">
            <div className="font-bold text-4xl">tastytradeur</div>
          </a>
        </div>
      </header>
    </>
  );
};
