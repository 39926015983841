// src/component/watchlist.tsx

import { useTastyAuthContext } from "../context/tasty-auth-context";
import { WatchlistRow } from "./watchlistRow";

export const Watchlist = () => {
  const { isLoggedIn } = useTastyAuthContext();

  if (!isLoggedIn)
    return <div className="py-3 px-4">Log in to view your watchlist</div>;

  const mywatchi = ["SPY", "AAPL", "MSFT", "XOM", "VIRT", "CMG"];

  return (
    <div className="px-3">
      <h1 className="uppercase pb-1 font-bold">Watchlist</h1>
      <table className="w-full ">
        <thead className="">
          <tr className="text-right">
            <th className="text-left pb-2 font-normal">Symbol</th>
            <th className="pb-2 font-normal">Last</th>
            <th className="pb-2 font-normal">Bid</th>
            <th className="pb-2 font-normal">Ask</th>
          </tr>
        </thead>
        <tbody>
          {mywatchi.map((symbol) => (
            <WatchlistRow stockTicker={symbol} key={symbol} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
