// src/context/context.ts

// src/context/state.ts
import { createContext } from 'react';
import TastytradeClient, { QuoteStreamer } from "@tastytrade/api"

class TastytradeContext {
    static Instance = new TastytradeContext('https://api.cert.tastyworks.com', 'wss://streamer.cert.tastyworks.com');
    public tastytradeApi: TastytradeClient
    public accountNumbers: string[] = []
    public quoteStreamer: QuoteStreamer | null = null

    constructor(baseUrl: string, accountStreamerUrl: string) {
        this.tastytradeApi = new TastytradeClient(baseUrl, accountStreamerUrl)
    }

    setupQuoteStreamer(token: string, url: string) {
        if (!this.quoteStreamer) {
            this.quoteStreamer = new QuoteStreamer(token, url)
        }

        return this.quoteStreamer
    }

    get isLoggedIn() {
        return this.tastytradeApi.session.isValid
    }
}

const AppContext = createContext(TastytradeContext.Instance)

export { AppContext, TastytradeContext };