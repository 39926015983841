import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { Root } from "./pages/root";
import { Login } from "./pages/login";
import { App } from "./pages/app";
import { Ticker } from "./pages/ticker";
import { Tickers } from "./pages/tickers";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "login", element: <Login /> },
      {
        path: "",
        element: <App />,
        children: [
          { path: "", action: () => redirect("/symbols") },
          {
            path: "symbols",
            element: <Tickers />,
            children: [
              {
                path: ":symbol",
                element: <Ticker />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
